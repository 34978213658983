<template>
  <page-main title="Your Saved Resumes" noBottom :error="error">
    <template v-slot:aside>
      All your saved resumes can be found here. 
      <li>You can view, download, or delete any saved resume at any time.</li>
      <li>Saved resumes cannot be edited, but you can save as many different resumes as you wish, each customized according to your preferences.</li>
      <li>If you'd like to customize your latest resume, use the navigation bar to return to any resume section or to the 'Resume Review' page.</li>
    </template>
    <v-container>
      <v-overlay color="primary" :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel" multiple>
            <r-expansion-panel
              v-for="resume in localResumes"
              :key="resume.title"
            >
              <r-expansion-panel-header>
                <div class="d-flex align-center justify-space-between">
                  <div class="text-body-1">{{ resume.title }}</div>
                  <div class="text-body-2 pr-2">
                    {{ getLength(resume.reviews) }}
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row
                    class="pt-4 pb-2"
                    v-for="r in resume.reviews"
                    :key="r.id"
                  >
                    <v-col cols="12" class="r-col">
                      <resume-submission
                        :id="r.id"
                        :name="r.name"
                        :note="r.note"
                        :subLines="buildSublines(r)"
                        :status="r.status"
                        :imgHref="r.previewRef"
                        :fileHref="r.fileRef"
                        :filename="r.fileName"
                        @updateData="loadData()"
                        @error="setError($event)"
                        @removeResume="triggerRemove($event)"
                      >
                      </resume-submission>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog
      v-model="removeDialog"
      title="Confirm Removal"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          Are you sure you want to remove this saved resume?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined @click="removeDialog = false"
          >Cancel</v-btn
        >
        <v-btn color="primary" outlined @click="removeResume">Remove</v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as disp from "../utility/display";
import * as dutil from "../utility/dateutil";
import ResumeSubmission from "../components/ResumeSubmissionLine.vue";

// @ is an alias to /src
export default {
  name: "SavedResumes",
  data: () => ({
    panel: [0],
    localResumes: [
      {
        title: "Saved Resumes",
        reviews: [],
      },
    ],
    loading: true,
    error: "",
    removeId: 0,
    removeDialog: false,
  }),
  components: {
    "resume-submission": ResumeSubmission,
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapActions("userSubmissions", ["loadResumeSubmissions"]),
    ...mapActions("currentReview", ["removeSavedResume"]),
    async loadData() {
      try {
        this.loading = true;
        await this.loadResumeSubmissions();
      } finally {
        this.loading = false;
      }
    },
    getLength(resumes) {
      if (resumes != null) {
        return `(${resumes.length})`;
      }
      return "(-)";
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    updateReviews() {
      this.localResumes[0].reviews = [];
      this.resumes.forEach((r) => {
        this.localResumes[0].reviews.push(r);
      });
    },
    buildSublines(r) {
      let lines = [];

      if (!disp.IsNullorWhitespace(r.dateSubmitted)) {
        lines.push(`Saved: ${dutil.dateStringToFormat(r.dateSubmitted)}`);
      }
      if (!disp.IsNullorWhitespace(r.note)) {
        lines.push(`Note: ${r.note}`);
      }
      return lines;
    },
    setError(error) {
      this.error = error;
    },
    triggerRemove(id) {
      this.removeDialog = true;
      this.removeId = id;
    },
    async removeResume() {
      try {
        this.loading = true;
        await this.removeSavedResume({ reviewId: this.removeId });
        await this.loadData();
      } finally {
        this.removeDialog = false;
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapFields("userSubmissions", ["resumes"]),
  },
  watch: {
    resumes: {
      handler() {
        this.updateReviews();
      },
    },
  },
};
</script>


<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}

.button-holder {
  min-width: 140px;
}
</style>