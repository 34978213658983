<template>
  <div class="d-flex justify-space-between align-start">
    <div class="wide">
      <p class="text-body-1 mb-1">{{ name }}</p>
      <p
        v-for="(line, i) in subLines"
        :key="i"
        class="text-body-2 text--secondary mb-0 note"
      >
        {{ line }}
      </p>
    </div>
    <div class="d-flex pl-2">
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="d-flex justify-end align-start"
      >
        <div class="pr-3">
          <img
            aspect-ratio="1"
            width="130"
            max-width="130"
            :src="imgHref"
            contain
            class="pa-1 resume-img"
          />
        </div>
      </div>
      <div class="button-holder">
        <v-btn outlined block @click="handleView()"> View Resume </v-btn>
        <v-btn class="mt-2" outlined block @click="handleRemove()">
          Delete Saved Resume
        </v-btn>
        <v-btn block outlined class="mt-2" @click="handleDownload()">
          Download Word (.docx)
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as disp from "../utility/display.js";
import { mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "ResumeSubmissionLine",
  data: () => ({}),
  components: {},
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    note: {
      type: String,
      default: "Select a Resume Format",
    },
    subLines: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: "",
    },
    imgHref: {
      type: String,
      default: "",
    },
    fileHref: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
    },
  },
  mounted() {},
  computed: {
    withdrawn() {
      return this.status === "Withdrawn";
    },
    submitted() {
      return this.status === "Submitted";
    },
    reviewed() {
      return this.status === "Reviewed";
    },
    ...mapFields("currentReview", ["loadResumeError"]),
    noteArr() {
      if (this.note == null || this.note == "") {
        return [];
      }
      return this.note.split("\n");
    },
  },
  methods: {
    ...mapMutations("userSubmissions", ["setCurrentResume"]),
    ...mapActions("currentReview", ["loadReviewUser"]),
    async handleView() {
      await this.loadReviewUser({ reviewId: this.id });
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.$emit("error", "Unable to load your resume. Please try again.");
      } else {
        this.nav(`/ResumeReview/${this.id}`);
      }
    },
    async handleRemove() {
      this.$emit("removeResume", this.id);
    },
    async handleDownload() {
      this.downloadFileFromUrl(this.fileHref);
    },
    downloadFileFromUrl(fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
};
</script>

<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}
.note {
  word-wrap: break-word;
  max-width: 300px;
}
</style>